@tailwind base;
@tailwind components;
@tailwind utilities;

/*html {*/
  /*height: 100vh;*/
/*}*/

/*body {*/
  /*height: 100vh;*/
/*}*/
